import {Link} from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found"/>
        <div className="container mx-auto px-16 py-32">
            <div className="text-center">
                <StaticImage
                    src="../images/not-found.png"
                    alt="Error 404"
                    width="420"
                    className="rounded-md mt-5"
                />
                <div className="mt-5">
                    <p className="text-xl">
                        Sorry, you just hit a page that does not exists.
                    </p>
                    <p className="text-xl">
                        Would you like to go back to{" "}
                        <Link to="/" className="text-blue-500">
                            home
                        </Link>{" "}
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
